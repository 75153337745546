import { Locale } from '../locale';

const es = {
  [Locale.SV]: 'Svenska',
  [Locale.EN]: 'English',
  [Locale.ES]: 'Español',

  'header:social_media': 'Redes sociales',

  'login:header': 'Iniciar Sesión',
  'login:buy': 'Comprar acceso',
  'login:description':
    '1. Ingrese el mismo número de teléfono o dirección de correo electrónico que utilizó para comprar el boleto\n2. Recibirás un SMS o email con un código\n3. Introduce el código y haz clic en Iniciar sesión',
  'login:email': 'Correo Electrónico',
  'login:or': 'O',
  'login:phone': 'Numero de Teléfono',
  'login:send_otp': 'Enviar Código',
  'login:otp': 'Código',
  'login:unlock': 'Iniciar Sesión',
  'login:error:access':
    'Si ha comprado el contenido recientemente, tenga en cuenta que puede tardar hasta cinco minutos para que pueda iniciar sesión.',
  'login:error:unknown':
    'Se produjo un error desconocido, inténtelo de nuevo más tarde. Contáctenos en support@waoplay.com si necesita ayuda.',
  'login:error:otp':
    'Parece que introdujo el código incorrecto, verifique su código y vuelva a intentarlo. Contáctenos en support@waoplay.com si necesita ayuda.',

  'hero:live': 'En Directo',
  'hero:more': 'Ver más',

  'player:language': 'Traducción',
  'player:manifest:original': 'Ninguna',
  'player:manifest:en': 'English',
  'player:manifest:es': 'Español',
  'player:manifest:sv': 'Svenska',

  'player:help': 'Ayuadame',
  'player:donate': 'Da tu ofrenda',

  'player:help:restart': 'Reinicia tu computadora.',
  'player:help:browser': 'Prueba con otro navegador.',
  'player:help:device': 'Prueba con otro dispositivo.',
  'player:help:audio':
    '¿Escuchas el sonido doble? ¿Tienes varias pestañas del navegador abiertas?',
  'player:help:update':
    'Solo admitimos la última versión de su sistema, asegúrese de tener las últimas actualizaciones.',
  'player:help:facebook': '¿Sabes que también emitimos por facebook? ',
  'player:help:facebook:link': 'Haga click aquí.',

  'player:donate:give': 'Da tu ofrenda',
  'player:donate:other': 'Del extranjero',

  'cookies:text': 'Usamos cookies, haga clic aquí para leer más',
  'cookies:approve': 'Aceptar'
};

export default es;
