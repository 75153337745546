import { createContext } from 'preact';
import { useContext } from 'preact/hooks';
import { v4 as uuid } from 'uuid';

const STORAGE_KEY = 'waoplay:session_id';

let sessionId = localStorage.getItem(STORAGE_KEY);
if (!sessionId) {
  sessionId = uuid();
  localStorage.setItem(STORAGE_KEY, sessionId);
}

const SessionContext = createContext(sessionId);

export function useSessionId() {
  const sessionId = useContext(SessionContext);
  return sessionId;
}

export function SessionProvider({ children }) {
  return (
    <SessionContext.Provider value={sessionId}>
      {children}
    </SessionContext.Provider>
  );
}
