function Close({ className, onClick }) {
  return (
    <svg
      onClick={onClick}
      class={className}
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Group"
          transform="translate(8.000000, 8.000000) rotate(-315.000000) translate(-8.000000, -8.000000) translate(-1.000000, -1.000000)"
          fill="#FFFFFF"
          fill-rule="nonzero"
        >
          <rect
            id="Rectangle"
            transform="translate(9.020639, 8.910098) rotate(-270.000000) translate(-9.020639, -8.910098) "
            x="7.67735541"
            y="-0.0451259328"
            width="2.68656716"
            height="17.9104478"
          ></rect>
          <rect
            id="Rectangle-Copy"
            transform="translate(9.097948, 8.961590) rotate(-180.000000) translate(-9.097948, -8.961590) "
            x="7.75466418"
            y="0.00636660448"
            width="2.68656716"
            height="17.9104478"
          ></rect>
        </g>
      </g>
    </svg>
  );
}

export default Close;
