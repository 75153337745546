import { Locale, useLocale } from './locale';
import sv from './translations/sv';
import en from './translations/en';
import es from './translations/es';

const Translations = {
  [Locale.SV]: sv,
  [Locale.EN]: en,
  [Locale.ES]: es,
};

export function useTranslate() {
  const [locale] = useLocale();
  return (key) => Translations[locale]?.[key] ?? key;
}
