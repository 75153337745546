import classNames from 'classnames';
import { Fragment, h } from 'preact';

import Close from '../../components/icons/Close';
import { useTranslate } from '../../util/translate';
import style from './style.scss';

export function Help() {
  const t = useTranslate();
  return (
    <div class={style.help}>
      <h2>{t('player:help')}</h2>
      <ul>
        <li>{t('player:help:restart')}</li>
        <li>{t('player:help:browser')}</li>
        <li>{t('player:help:device')}</li>
        <li>{t('player:help:audio')}</li>
        <li>{t('player:help:update')}</li>
        <li>
          {t('player:help:facebook')}
          <a
            href="https://www.facebook.com/We-are-one-church-102373367928774"
            target="_blank"
          >
            {t('player:help:facebook:link')}
          </a>
        </li>
      </ul>
    </div>
  );
}

export function Donate() {
  const t = useTranslate();
  return (
    <Fragment>
      <h2>{t('player:donate:give')}</h2>
      <div>
        <h3>Swish</h3>
        <p class="Type-large">123 099 9045</p>
      </div>
      <div>
        <h3>Plusgiro</h3>
        <p class="Type-large">15 76 40 - 4</p>
      </div>
      <div>
        <h3>PayPal</h3>
        <form
          action="https://www.paypal.com/donate"
          method="post"
          target="_blank"
        >
          <input type="hidden" name="hosted_button_id" value="Y2THH2GFBFMH4" />
          <input
            type="image"
            src="https://www.paypalobjects.com/en_US/SE/i/btn/btn_donateCC_LG.gif"
            border="0"
            name="submit"
            title="PayPal - The safer, easier way to pay online!"
            alt="Donate with PayPal button"
          />
          <img
            alt=""
            border="0"
            src="https://www.paypal.com/en_SE/i/scr/pixel.gif"
            width="1"
            height="1"
          />
        </form>
      </div>
      <div>
        <h3>{t('player:donate:other')}</h3>
        <p>
          <b>Payee:</b> Sodermalmskyrkan, Box 20033, S-104 60 Stockholm, Sweden
          <br />
          <b>Bank:</b> Nordeabank, S-105 71 Stockholm
          <br />
          <b>IBAN:</b> SE2695000099602601576404
          <br />
          <b>BIC:</b> NDEASESS
        </p>
      </div>
    </Fragment>
  );
}
export default function Popup({ className, children, closeHandler }) {
  return (
    <div
      class={classNames(style.popup, className)}
      onClick={(evt) => evt.target === evt.currentTarget && closeHandler()}
    >
      <div class={style.popupWrapper}>
        <Close className={style.popupClose} onClick={closeHandler} />
        {children}
      </div>
    </div>
  );
}
