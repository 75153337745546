import { h } from 'preact';
import { useState } from 'preact/hooks';

import { useTranslate } from '../../util/translate';
import style from './style.scss';

const COOKIE_KEY = 'waoplay:cookies';
function getCookiesApproved() {
  let approved;
  try {
    approved = localStorage.getItem(COOKIE_KEY);
  } catch (e) {}
  return !!approved;
}

function setCookiesApproved(approved) {
  try {
    localStorage.setItem(COOKIE_KEY, approved);
  } catch (e) {}
}
export default function CookiesBanner() {
  const [approved, setApproved] = useState(getCookiesApproved());
  const t = useTranslate();
  if (approved) {
    return null;
  }

  return (
    <div class={style.banner}>
      <a href="https://www.waochurch.com/integritetspolicy/" target="_blank">
        {t('cookies:text')}
      </a>
      <button
        onClick={() => {
          setCookiesApproved(true);
          setApproved(true);
        }}
      >
        {t('cookies:approve')}
      </button>
    </div>
  );
}
