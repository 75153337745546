import { createContext } from 'preact';
import { useContext, useEffect, useState } from 'preact/hooks';

export const Locale = {
	SV: 'sv',
	EN: 'en',
	ES: 'es',
};

const LOCALE_KEY = 'waoplay:locale';

function getDefaultLocale() {
  const url = new URL(window.location.href);
  const queryLocale = url.searchParams.get("locale");
  if (Locale[queryLocale]) {
  	return Locale[queryLocale];	
  }
	let locale;
	try {
		locale = localStorage.getItem(LOCALE_KEY);
	} catch (e) {}

	if (!locale) {
		locale = Locale.SV;
	}
	return locale;
}

function setDefaultLocale(locale) {
	try {
		localStorage.setItem(LOCALE_KEY, locale);
	} catch (e) {}
}

const LocaleContext = createContext();

export function useLocale() {
	return useContext(LocaleContext);
}

export function LocaleProvider({ children }) {
	const [locale, setLocale] = useState(getDefaultLocale());
	useEffect(() => setDefaultLocale(locale), [locale]);
	return (
		<LocaleContext.Provider value={[locale, setLocale]}>
			{children}
		</LocaleContext.Provider>
	);
}
