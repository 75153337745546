import { h } from 'preact';
import classNames from 'classnames';
import style from './style.scss';

export default function Footer() {
  return (
    <div class={classNames(style.footer)}>
      {`© WE ARE ONE CHURCH`}
    </div>
  );
}
