import { Locale } from '../locale';

const sv = {
  [Locale.SV]: 'Svenska',
  [Locale.EN]: 'English',
  [Locale.ES]: 'Español',

  'header:social_media': 'Sociala medier',

  'login:header': 'Logga in',
  'login:buy': 'Köp access',
  'login:description':
    '1. Skriv in samma telefonnummer eller e-postadress som du köpte biljetten med\n2. Du får sms eller mail med en kod\n3. Skriv in koden och klicka på Logga in',
  'login:email': 'E-post',
  'login:or': 'Eller',
  'login:phone': 'Telefonnummer',
  'login:send_otp': 'Skicka kod',
  'login:otp': 'Kod',
  'login:unlock': 'Logga in',
  'login:error:access':
    'OBS. Har du precis köpt access till sidan så kan det ta upp till fem minuter innan du kan logga in.',
  'login:error:unknown':
    'Ett okänt fel inträffade, försök igen lite senare. Kontakta oss på support@waoplay.com om du behöver hjälp.',
  'login:error:otp':
    'Det verkar som du angav fel nyckel, dubbelkolla och försök igen. Kontakta oss på support@waoplay.com om du behöver hjälp.',

  'hero:live': 'Live nu',
  'hero:more': 'Se mer',

  'player:language': 'Tolkning',
  'player:manifest:original': 'Ingen',
  'player:manifest:en': 'English',
  'player:manifest:es': 'Español',
  'player:manifest:sv': 'Svenska',

  'player:help': 'Hjälp',
  'player:donate': 'Ge en gåva',

  'player:help:restart': 'Starta om datorn.',
  'player:help:browser': 'Prova en annan webbläsare.',
  'player:help:device': 'Prova en annan enhet.',
  'player:help:audio':
    'Hör du ljudet två gånger? Kolla om du har flera flikar uppe.',
  'player:help:update':
    'Vi stödjer endast den senaste versionen av ditt system, kontrollera att du har de senaste uppdateringarna.',
  'player:help:facebook': 'Du vet väl att vi sänder på facebook också? ',
  'player:help:facebook:link': 'Klicka här.',

  'player:donate:give': 'Ge ett offer',
  'player:donate:other': 'Från utlandet',

  'cookies:text': 'Vi använder cookies, klicka här för att läsa mer',
  'cookies:approve': 'Godkänn'
};

export default sv;
