import { h } from "preact";
import { Link } from "preact-router";
import waoLogo from "../../assets/wao-logo-white.svg";
import style from "./style.scss";

export default function Logo() {
	return (
		<div class={style.logo}>
			<img src={waoLogo} />
			<span>WAO</span>
			<span>PLAY</span>
		</div>
	);
}
