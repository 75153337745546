import { h } from 'preact';
import { Router } from 'preact-router';
import { useState } from 'preact/hooks';

import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import CookiesBanner from './components/cookiesBanner/CookiesBanner';
import Home from './routes/home';
import Player from './routes/player';
import Series from './routes/series';
import { LocaleProvider } from './util/locale';
import { SessionProvider } from './util/session';

let old = history.pushState;
history.pushState = function () {
	old.apply(this, arguments);
	scrollTo(0, 0);
};

export default () => {
	const [background, setBackground] = useState(null);
	const [menuInformation, setMenuInformation] = useState(null);
	return (
		<SessionProvider>
			<LocaleProvider>
				<div id="app">
					{background && (
						<div
							id="background"
							style={{ backgroundImage: `url(${background})` }}
						/>
					)}
					<Header menuInformation={menuInformation} />
					<Router>
						<Home
							default
							path="/"
							setBackground={setBackground}
							setMenuInformation={setMenuInformation}
						/>
						<Player
							path="/episode/:episodeId"
							setBackground={setBackground}
							setMenuInformation={setMenuInformation}
						/>
						<Series
							path="/series/:seriesId/episode/:episodeId"
							setBackground={setBackground}
							setMenuInformation={setMenuInformation}
						/>
						<Series
							path="/series/:seriesId"
							setBackground={setBackground}
							setMenuInformation={setMenuInformation}
						/>
					</Router>
					<Footer />
					<CookiesBanner />
				</div>
			</LocaleProvider>
		</SessionProvider>
	);
};
