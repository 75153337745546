import classNames from 'classnames';
import { Link } from 'preact-router';
import { useEffect, useState } from 'preact/hooks';

import { Locale, useLocale } from '../../util/locale';
import { useTranslate } from '../../util/translate';
import Popup from '../popup/Popup';
import Logo from './Logo';
import style from './style.scss';

function stripHTML(string) {
	let domElement = new DOMParser().parseFromString(string, 'text/html');
	return domElement.body.textContent || '';
}

export default function Header({ menuInformation }) {
	const t = useTranslate();
	const [locale, setLocale] = useLocale();
	const [isMenuInformationOpen, setIsMenuInformationOpen] = useState(false);
	const [scrolled, setScrolled] = useState(
		document.scrollingElement?.scrollTop > 0
	);

	let menuInformationTitle = menuInformation?.[locale]?.split('\n')[0];
	if (
		menuInformationTitle?.includes('<h1>') &&
		menuInformationTitle?.includes('</h1>')
	) {
		menuInformationTitle = stripHTML(menuInformationTitle);
	}

	useEffect(() => {
		const onScroll = () => {
			if (document.scrollingElement?.scrollTop > 0) {
				setScrolled(true);
			} else {
				setScrolled(false);
			}
		};
		document.addEventListener('scroll', onScroll);
		return () => document.removeEventListener('scroll', onScroll);
	}, []);

	return (
		<header class={classNames(style.header, { [style.scrolled]: scrolled })}>
			{isMenuInformationOpen && (
				<Popup closeHandler={() => setIsMenuInformationOpen(false)}>
					<div
						dangerouslySetInnerHTML={{ __html: menuInformation?.[locale] }}
					/>
				</Popup>
			)}
			<Link class={style.logoContainer} href="/">
				<Logo />
			</Link>
			<div class={style.links}>
				<a href="https://www.waochurch.com/" target="_blank">
					WAOCHURCH.COM
				</a>
				<a href="https://www.instagram.com/waochurch/" target="_blank">
					{t('header:social_media')}
				</a>
				{menuInformationTitle && (
					<button onClick={() => setIsMenuInformationOpen(true)}>
						{menuInformationTitle}
					</button>
				)}
			</div>
			<div class={style.locales}>
				{Object.values(Locale).map((val) => (
					<button
						class={locale === val ? style.active : ''}
						onClick={() => setLocale(val)}
					>
						{t(val)}
					</button>
				))}
			</div>
		</header>
	);
}
