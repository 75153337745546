import { Locale } from '../locale';

const en = {
  [Locale.SV]: 'Svenska',
  [Locale.EN]: 'English',
  [Locale.ES]: 'Español',

  'header:social_media': 'Social media',

  'login:header': 'Login',
  'login:buy': 'Buy access',
  'login:description':
    '1. Enter the same phone number or email address that you used to purchase the ticket\n2. You will receive an SMS or email with a code\n3. Enter the code and click Log in',
  'login:email': 'E-mail',
  'login:or': 'Or',
  'login:phone': 'Phonenumber',
  'login:send_otp': 'Send code',
  'login:otp': 'Code',
  'login:unlock': 'Login',
  'login:error:access':
    'If you\'ve recently purchased the content please note that it can take up to five minutes until you can login.',
  'login:error:unknown':
    'An unknown error occurred, please try again later. Contact us at support@waoplay.com if you need help.',
  'login:error:otp':
    'It seems like you provided the wrong code, check your input and try again. Contact us at support@waoplay.com if you need help.',

  'hero:live': 'Live now',
  'hero:more': 'See more',

  'player:language': 'Interpretation',
  'player:manifest:original': 'None',
  'player:manifest:en': 'English',
  'player:manifest:es': 'Español',
  'player:manifest:sv': 'Svenska',

  'player:help': 'Help',
  'player:donate': 'Give a gift',

  'player:help:restart': 'Restart your computer.',
  'player:help:browser': 'Try another browser.',
  'player:help:device': 'Try another device.',
  'player:help:audio':
    'Do you hear the audio twice? Check if you have multple tabs open.',
  'player:help:update':
    'We only support the latest version of your system, please make sure you have the latest updates.',
  'player:help:facebook': 'We send our meetings on facebook as well, ',
  'player:help:facebook:link': 'Click here.',

  'player:donate:give': 'Give an offering',
  'player:donate:other': 'From other countries',

  'cookies:text': 'We use cookies, click here to read more',
  'cookies:approve': 'Approve'
};

export default en;
